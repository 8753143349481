import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';

@Injectable({ providedIn: 'root' })
export class CertificadoNotasService
{
  constructor(private http: HttpClient, private notificationService: NotificationService,private loaderService: NgxUiLoaderService) {
  }

  getData() 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_CERTIFICADO_UNIDADES).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getDataNotas(objNota: any) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_CERTIFICADO_NOTAS +'?gradoId=' + objNota.gradoId 
                                                      + '&carreraId=' + objNota.carreraId 
                                                      + '&seccionId=' + objNota.seccionId 
                                                      + '&cicloId=' + objNota.cicloId
                                                      + '&unidadId=' + objNota.unidadId
                                                      + '&estudianteId=' + objNota.estudianteId).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getReporte(objNota: any) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_CERTIFICADO_NOTAS_PDF +'?gradoId=' + objNota.gradoId 
                                                      + '&carreraId=' + objNota.carreraId 
                                                      + '&seccionId=' + objNota.seccionId 
                                                      + '&cicloId=' + objNota.cicloId
                                                      + '&estudianteId=' + objNota.estudianteId
                                                      + '&tipo=' + objNota.tipo
                                                      ).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getReporteAcademico({estudianteId, estudianteCiclo, carreraId, tipo})
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      const Params: any = { estudianteId, estudianteCiclo, carreraId, tipo };
      this.http.get(apiRoutes.ROUTE_CERTIFICADO_NOTAS_ACADEMICAS_PDF, { params: Params }).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

  getReporteMateriaAcademico(estudianteId: number, materiaId: number, cicloId: number)
  {
    console.log("body", { estudianteId, materiaId, cicloId });
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_CERTIFICADO_MATERIA_ACADEMICA_PDF, { estudianteId, materiaId, cicloId }).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }

}