export const cEstadoVenta = {
    noDisponible: -1,
    creado: 0,
    habilitado: 1,
    deshabilitado: 2,
    bloqueado: 3,
    noVerificado: 4,
    restableciendoPassword: 5,
    emitido: 6,
    anulado: 7,
    certificado: 8,
    enProceso: 9,
    fallida: 10,
    cancelado: 16
}

export const lstEstadoVenta = [
    {
        estadoVentaId: cEstadoVenta.certificado,
        estadoVentaNombre: 'Certificado'
    },
    {
        estadoVentaId: cEstadoVenta.cancelado,
        estadoVentaNombre: 'Cancelado'
    },
    {
        estadoVentaId: cEstadoVenta.enProceso,
        estadoVentaNombre: 'En proceso'
    },
    {
        estadoVentaId: cEstadoVenta.anulado,
        estadoVentaNombre: 'Anulado'
    },
    {
        estadoVentaId: cEstadoVenta.fallida,
        estadoVentaNombre: 'Fallida'
    }
]