<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
      href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

    <!--
    <div class="search" (click)="searchAreaClick($event)">
      <input placeholder="Search..." [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
      <span class="search-icon" (click)="searchClick($event)">
        <i class="simple-icon-magnifier"></i>
      </span>
    </div>
    <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
      <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
        {{currentLanguage | uppercase}} <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item"
            href="javascript:;">{{item.label}}</a></li>
      </ul>
    </div>
    -->
  </div>

  <a class="navbar-logo" [routerLink]="adminRoot" >
    <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span>
  </a>

  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">

      <div class="d-none d-md-inline-block align-text-bottom mr-1">
        <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive"
            (change)="onDarkModeChange($event)">
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div>
      </div>

      <div class="position-relative d-none d-sm-inline-block" *ngIf="lstStudent && lstStudent.length > 1">
        <button type="button" class="header-icon btn btn-empty" (click)="openModal()">
          <i class="iconsminds-male-female d-block"></i>
        </button>
      </div>

      <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
        (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>

    </div>

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name">{{displayName}}</span>
        <span>
          <img alt="Profile Picture" [src]="fotografia" />
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <a class="dropdown-item c-pointer" (click)="updatePasswordModal()">Reiniciar contraseña</a>
        <a class="dropdown-item c-pointer" (click)="cambiarRolModal()">Cambiar Rol</a>
        <a class="dropdown-item c-pointer" (click)="onSignOut()">Cerrar Sesión</a>
      </div>
    </div>

  </div>
</nav>

<!-- STUDENT MODAL -->
<ng-template #studentModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Estudiantes</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xlg-4" *ngFor="let student of lstStudent" (click)="selectStudent(student)">
        <img class="social-header card-img-top" style="height: 30%" src="/assets/img/scholae/wallpaper.png" />
        <div [class]="student.selected ? 'card mb-4 card-selected' : 'card mb-4'" *ngIf="student" style="border-top-left-radius: initial; border-top-right-radius: initial; height: 60%;">
          <img *ngIf="student" alt="Profile" src="/assets/img/profiles/student-boy.png" class="img-thumbnail card-img social-profile-img c-pointer">
          <div class="card-body">
            <div class="text-center pt-4">
              <p class="list-item-heading pt-2">{{ student.estudianteNombre }}</p>
              <p class="text-muted list-item-heading pt-2">Colegio</p>
              <h5><b>{{ student.colegioNombre }}</b></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modalRef.hide()">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="saveStudent(); modalRef.hide()">Aplicar</button>
  </div>
</ng-template>

<ng-template #RolModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left"><b><i class="fas fa-atlas"></i> &nbsp; Cambio de rol</b></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">

      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xlg-4" *ngFor="let rol of authRol">
        <img class="social-header card-img-top" style="height: 30%" src="/assets/img/scholae/wallpaper.png" />
        <div class="card mb-4" style="border-top-left-radius: initial; border-top-right-radius: initial; height: 60%;">
          <img [src]="rol.rolIcono" class="img-thumbnail card-img social-profile-img c-pointer">
          <div class="card-body">
            <div class="text-center pt-4">
              <p class="list-item-heading pt-2">{{ rol.rolNombre }}</p>
              <input type="radio" name="{{ 'rol_' + rol.rolId }}" [value]="rol.rolId" [(ngModel)]="intRolId">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="hideModal();">Cancelar</button>
    <button type="submit" class="btn btn-primary float-right"
      (click)="submitCambioRol()">Aceptar</button>
  </div>
</ng-template>


<ng-template #passwordModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left"><b><i class="fas fa-atlas"></i> &nbsp; Reiniciar contraseña</b></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalPassword();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xlg-8">










      <form #passwordForm="ngForm">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xlg-3">
            <div class="position-relative" style="width: 80%; margin: auto; text-align: center; margin-bottom: 5%;">
              <img src="https://qa-agenciasway-simply-assets.s3.amazonaws.com/auth/change-password.png"/>
            </div>
          </div>
          <!-- PASSWORD -->
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xlg-6">
            <div class="form-group">
              <label class="form-group has-float-label">
                <span>Contraseña actual*</span>
                <input class="form-control" [type]="blnPassword ? 'text' : 'password'" required ngModel #Password="ngModel" name="Password" [(ngModel)]="objCambio.password" />
              </label>
            <div class="password-toggle" (click)="togglePasswordVisibility(1)" style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;">
              <i class="fas" [ngClass]="blnPassword ? 'fa-eye-slash' : 'fa-eye'" style="color: #999;"></i>
            </div>
          </div>
  
  
  
            <hr>
            <!-- NEW PASSWORD -->
            <div class="form-group">
              <label class="form-group has-float-label">
                <span>Nueva contraseña*</span>
                <input class="form-control" [type]="blnNewPassword ? 'text' : 'password'" required ngModel #NewPassword="ngModel" name="NewPassword" [(ngModel)]="objCambio.newPassword" />
              </label>
            <div class="password-toggle" (click)="togglePasswordVisibility(2)" style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;">
              <i class="fas" [ngClass]="blnNewPassword ? 'fa-eye-slash' : 'fa-eye'" style="color: #999;"></i>
            </div>
          </div>
  
  
            <div class="form-group">

            <label class="form-group has-float-label">
              <span>Nueva contraseña*</span>
              <input class="form-control" [type]="blnRepeatPassword ? 'text' : 'password'" required ngModel #NewPassword="ngModel" name="NewPassword" [(ngModel)]="objCambio.rePassword" />
            </label>
            <div class="password-toggle" (click)="togglePasswordVisibility(3)" style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;">
              <i class="fas" [ngClass]="blnRepeatPassword ? 'fa-eye-slash' : 'fa-eye'" style="color: #999;"></i>
            </div>
          </div>
            <alert *ngIf="objCambio.newPassword != null && objCambio.rePassword != null && objCambio.newPassword != objCambio.rePassword" type="danger rounded">
              La nueva contraseña con su confirmación deben de ser iguales.
            </alert>
          </div>

        </div>
      </form>





      
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="hideModalPassword();">Cancelar</button>
    <button type="submit" class="btn btn-primary float-right"
      (click)="submitUpdatePassword()">Aceptar</button>
  </div>
</ng-template>
