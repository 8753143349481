import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';

@Injectable({ providedIn: 'root' })
export class DtesPDF {
    constructor(private http: HttpClient, private notificationService: NotificationService, private loaderService: NgxUiLoaderService) {
    }

    getAllDTE(fInicio: any, fFin: any, intEstado: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(apiRoutes.ROUTE_DTE_PDF + '?fInicio=' + fInicio
                + '&fFin=' + fFin + '&estadoId=' + intEstado
            ).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                    resolve(null);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

}