export { apiRoutes } from "./api-routes.constants";
export { appConfig } from "./config.constants";
export { notificationTypes } from "./notification.constants";
export { statesElement } from "./states.constants";
export { statusCodes } from "./status-codes.constants";
export { pagination, arrPagination } from "./pagination.constants";
export { tangibilidadElement } from "./tangibilidad.constants";
export { tipoAjuste } from "./tipo-ajuste.constants";
export { carousel } from "./carousel.constants";
export { tipoDenominacion } from "./tipo-denominacion.constants";
export { configEditor } from "./editor.constants";
export { tipoProducto } from "./tipo-producto.constants";
export { tipoRenglon } from "./tipo-renglon.constants";
export { tipoDocumento, tipoDocumentoCobro, tipoDocumentoPago } from "./tipo-documento.constants";
export { cargaMasiva } from "./cargamasiva.constants"
export { cMetodoPago } from "./metodo-pago.constants";
export { cTipoVenta, cTipoBusquedaFacturar, lstBusquedaFacturar, cTipoDte, cTipoIdentificacion, cLstTipoIdentificacion } from "./tipo-venta.constants";
export { cTipoRegistroCuentaCorriente } from "./tipo-registro-cc.constants";
export { configCropper } from "./cropper.constants";
export { planesElements } from "./planes.constants";
export { cLogMoodle } from "./log-moodle.constants";
export { cEstadoVenta, lstEstadoVenta } from "./estado-venta.constants";
export { cTipoAprobacionNotas, cTipoAccionSolicitudNotas, cEstadoSolicitudNotas } from "./tipo-aprobacion-notas.constants";
export { tipoCertificado } from "./tipo-certificado-notas.constants";
export { cTipoAsistencia } from "./asistencia-tipo.constants";
export { tipoGeneracion } from "./tipo-generacion.constants";
export { cTipoRefacturacion } from './tipoRefacturacion.constants';
export { cTipoEdicionDTE } from './tipo-edicion-dte.constants';
export { cTipoInscripcion } from './tipo-inscripcion.constants';
export { cEstadoNota } from './estado-nota.constants';
export { statesMoodle } from "./statesMoodle.constants";

export const cPeriodicidad = {
    mensual: 200,
    individual: 201
}
