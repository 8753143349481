export const cTipoAprobacionNotas = {
    SOLICITANTE: 1,
    APROBADOR: 2
}

export const cTipoAccionSolicitudNotas = {
    SOLICITUD: 1,
    APROBAR: 2,
    RECHAZAR: 3
}

export const cEstadoSolicitudNotas = {
    PENDIENTE: 1,
    CERTIFICADA: 2,
    RECHAZADA: 3,
    CANCELADA: 4,
    ANULADA: 5
}