export const cTipoDte = {
    RECIBO: 1,
    FACTURA: 2
};
export const cLstTipoIdentificacion = [
    { tipoIdentificacion: 1, nombreIdentificacion: "NIT" },
    { tipoIdentificacion: 2, nombreIdentificacion: "DPI" }
]
export const cTipoIdentificacion = {
    NIT: 1,
    DPI: 2
};
export const cTipoVenta = {
    CREDITO: 1,
    CONTADO: 2
};
export const cTipoBusquedaFacturar = {
    NIT: 1,
    ESTUDIANTE: 2,
    SIN_ESTUDIANTE: 3,
    TODOS: 4
};
export const lstBusquedaFacturar = [
    {
        id: 1,
        name: 'NIT o CUI'
    },
    {
        id: 2,
        name: 'Por estudiante'
    },
    {
        id: 3,
        name: 'Sin estudiante'
    }
];